<template>
  <div class="home">
    <b-container>
      <StatusBar msg="Welcome to Web FreeCell" />
      <UpperArea />
      <CardsBoard />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import StatusBar from "@/components/StatusBar.vue";
import UpperArea from "@/components/UpperArea.vue";
import CardsBoard from "@/components/CardsBoard.vue";

export default {
  name: "Home",
  components: {
    StatusBar,
    UpperArea,
    CardsBoard,
  },
};
</script>
