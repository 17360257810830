<template>
  <div>
    <b-row class="m-1">
      <b-col md="5">
        <b-card-group deck>
          <div v-for="(card, index) in freeSlots" :key="index">
            <b-card
              v-if="card"
              :class="[
                'drag-el',
                'ma-n1',
                { 'red-suit': isRed(card), 'black-suit': !isRed(card) },
              ]"
              draggable
              @dragstart="startDrag($event, { freepos: index, card: card })"
            >
              <h3>{{ card }}</h3>
            </b-card>
            <b-card
              v-if="!card"
              @drop="onFreeDrop($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <b-card-text> Free </b-card-text>
            </b-card>
          </div>
        </b-card-group>
      </b-col>
      <b-col md="2">picture</b-col>
      <b-col md="5">
        <b-card-group deck>
          <div
            v-for="(card, index) in targetSlots"
            :key="index"
            class="ma-0 pa-0"
          >
            <b-card
              v-if="card"
              :class="[
                'drag-el',
                'mx-1',

                { 'red-suit': isRed(card), 'black-suit': !isRed(card) },
              ]"
              draggable
              @dragstart="startDrag($event, { targetpos: index, card: card })"
              @drop="onTargetDrop($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <h3>{{ card }}</h3>
            </b-card>
            <b-card
              class="mx-1"
              v-if="!card"
              @drop="onTargetDrop($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <b-card-text> Target </b-card-text>
            </b-card>
          </div>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row align-h="around">
      <b-col
        md="1"
        class="m-2"
        v-for="(cardColm, index) in cardDeck"
        :key="index"
      >
        <div
          class="list-group drop-zone"
          :list="cardColm"
          group="cardColm"
          @drop="onDrop($event, index)"
          @dragover.prevent
          @dragenter.prevent
        >
          <b-card
            v-for="(card, index2) in cardColm"
            :key="index2"
            :class="[
              'drag-el',
              { 'red-suit': isRed(card), 'black-suit': !isRed(card) },
            ]"
            :draggable="index2 == cardColm.length - 1"
            @dragstart="startDrag($event, { pos: index, card: card })"
            @dblclick="moveCardToFreePos({ pos: index, card: card })"
          >
            <h3>{{ card }}</h3>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import dealFreeCell from "@/logic/deck.js";

export default {
  data() {
    return {
      cardDeck: null,
      freeSlots: ["", "", "", ""],
      targetSlots: ["", "", "", ""],
      ranks: ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"],
    };
  },
  mounted() {
    this.cardDeck = dealFreeCell(164);
    console.log("this.cardDeck", this.cardDeck);
    //Write loop to add cards to cols
  },
  methods: {
    isRed(card) {
      return card[1] == "D" || card[1] == "H";
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";

      evt.dataTransfer.setData("freepos", item.freepos);
      evt.dataTransfer.setData("targetpos", item.targetpos);
      evt.dataTransfer.setData("pos", item.pos);
      evt.dataTransfer.setData("card", item.card);
      evt.dataTransfer.setData("cardOb", item);
    },
    onDrop(evt, list) {
      const pos = parseInt(evt.dataTransfer.getData("pos"));
      const freepos = parseInt(evt.dataTransfer.getData("freepos"));
      const targetpos = parseInt(evt.dataTransfer.getData("targetpos"));
      const card = evt.dataTransfer.getData("card");

      this.cardDeck[list].push(card);

      if (pos > -1) {
        this.cardDeck[pos].splice(this.cardDeck[pos].indexOf(card), 1);
      }
      if (freepos > -1) {
        this.freeSlots[freepos] = "";
      }
      if (targetpos > -1) {
        this.targetSlots[targetpos] = "";
      }
    },
    onFreeDrop(evt, list) {
      const pos = parseInt(evt.dataTransfer.getData("pos"));
      const card = evt.dataTransfer.getData("card");

      this.freeSlots[list] = card;

      this.cardDeck[pos].splice(this.cardDeck[pos].indexOf(card), 1);
    },
    onTargetDrop(evt, list) {
      const pos = parseInt(evt.dataTransfer.getData("pos"));
      const card = evt.dataTransfer.getData("card");
      if (this.targetSlots[list] == "" && card[0] !== "A") {
        return;
      }
      if (
        !this.targetSlots[list] == "" &&
        this.targetSlots[list][1] !== card[1]
      ) {
        return;
      }

      if (
        !this.targetSlots[list] == "" &&
        this.ranks.findIndex((item) => item === this.targetSlots[list][0]) +
          1 !==
          this.ranks.findIndex((item) => item === card[0])
      ) {
        return;
      }

      this.targetSlots[list] = card;

      this.cardDeck[pos].splice(this.cardDeck[pos].indexOf(card), 1);
    },
    moveCardToFreePos(cardEvent) {
      for (let index = 0; index < this.freeSlots.length; index++) {
        const element = this.freeSlots[index];
        if (element == "") {
          this.freeSlots[index] = cardEvent.card;
          this.cardDeck[cardEvent.pos].splice(
            this.cardDeck[cardEvent.pos].indexOf(cardEvent.card),
            1
          );
          break;
        }
      }
    },
  },
};
</script>

<style>
.red-suit {
  border-color: red;
  border-style: solid;
  border-width: medium;
}

.black-suit {
  border-color: black;
  border-style: solid;
  border-width: medium;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
