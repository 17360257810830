var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"md":"5"}},[_c('b-card-group',{attrs:{"deck":""}},_vm._l((_vm.freeSlots),function(card,index){return _c('div',{key:index},[(card)?_c('b-card',{class:[
              'drag-el',
              'ma-n1',
              { 'red-suit': _vm.isRed(card), 'black-suit': !_vm.isRed(card) } ],attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag($event, { freepos: index, card: card })}}},[_c('h3',[_vm._v(_vm._s(card))])]):_vm._e(),(!card)?_c('b-card',{on:{"drop":function($event){return _vm.onFreeDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('b-card-text',[_vm._v(" Free ")])],1):_vm._e()],1)}),0)],1),_c('b-col',{attrs:{"md":"2"}},[_vm._v("picture")]),_c('b-col',{attrs:{"md":"5"}},[_c('b-card-group',{attrs:{"deck":""}},_vm._l((_vm.targetSlots),function(card,index){return _c('div',{key:index,staticClass:"ma-0 pa-0"},[(card)?_c('b-card',{class:[
              'drag-el',
              'mx-1',

              { 'red-suit': _vm.isRed(card), 'black-suit': !_vm.isRed(card) } ],attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag($event, { targetpos: index, card: card })},"drop":function($event){return _vm.onTargetDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('h3',[_vm._v(_vm._s(card))])]):_vm._e(),(!card)?_c('b-card',{staticClass:"mx-1",on:{"drop":function($event){return _vm.onTargetDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('b-card-text',[_vm._v(" Target ")])],1):_vm._e()],1)}),0)],1)],1),_c('b-row',{attrs:{"align-h":"around"}},_vm._l((_vm.cardDeck),function(cardColm,index){return _c('b-col',{key:index,staticClass:"m-2",attrs:{"md":"1"}},[_c('div',{staticClass:"list-group drop-zone",attrs:{"list":cardColm,"group":"cardColm"},on:{"drop":function($event){return _vm.onDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},_vm._l((cardColm),function(card,index2){return _c('b-card',{key:index2,class:[
            'drag-el',
            { 'red-suit': _vm.isRed(card), 'black-suit': !_vm.isRed(card) } ],attrs:{"draggable":index2 == cardColm.length - 1},on:{"dragstart":function($event){return _vm.startDrag($event, { pos: index, card: card })},"dblclick":function($event){return _vm.moveCardToFreePos({ pos: index, card: card })}}},[_c('h3',[_vm._v(_vm._s(card))])])}),1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }